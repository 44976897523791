<div class="page__container">
    <div *ngIf="loading; else available_country">
        <dx-load-panel
            [showIndicator]="true"
            [showPane]="true"
            [shading]="true"
            [closeOnOutsideClick]="false"
        >
        </dx-load-panel>
    </div>

    <ng-template #available_country>
        <form (ngSubmit)="onFormSubmit()" [formGroup]="countryForm">
            <div class="dx-fieldset">
                <div class="dx-field">
                    <div
                        class="dx-field-label"
                        eciTranslate="app.countryManagement.name.title">
                    </div>
                    <div class="dx-field-value">
                        <dx-text-box formControlName="name">
                            <dx-validator>
                                <dxi-validation-rule
                                    type="required"
                                    [message]="'app.countryManagement.name.validation'| translate">
                                </dxi-validation-rule>
                            </dx-validator>
                        </dx-text-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div
                        class="dx-field-label"
                        eciTranslate="app.countryManagement.weekdays.title">
                    </div>
                    <div class="dx-field-value">
                        <dx-tag-box
                            [(items)]="weekdaysWithNames"
                            formControlName="selectedWeekdays"
                            valueExpr="value"
                            displayExpr="name"
                            [showDropDownButton]="true"
                            [showSelectionControls]="true"
                            [placeholder]="'app.countryManagement.weekdays.placeholder'| translate">
                            <dx-validator>
                                <dxi-validation-rule
                                    type="required"
                                    [message]="'app.countryManagement.weekdays.validation'| translate">
                                </dxi-validation-rule>
                            </dx-validator>
                        </dx-tag-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div
                        class="dx-field-label"
                        eciTranslate="app.countryManagement.timeZone.title"
                    ></div>
                    <div class="dx-field-value">
                        <dx-select-box
                            [items]="timeZones"
                            formControlName="timeZone"
                            [showDataBeforeSearch]="true"
                            [searchEnabled]="true"
                            [placeholder]="'app.countryManagement.timeZone.placeholder'| translate">
                            <dx-validator>
                                <dxi-validation-rule
                                    type="required"
                                    [message]="'app.countryManagement.timeZone.validation'| translate">
                                </dxi-validation-rule>
                            </dx-validator>
                        </dx-select-box>
                    </div>
                </div>
                <div class="dx-field">
                    <div
                        class="dx-field-label"
                        eciTranslate="app.countryManagement.standardDeliveryDays.title"
                    ></div>
                    <div class="dx-field-value">
                        <dx-number-box
                            formControlName="standardDeliveryDays"
                            [showSpinButtons]="true"
                            [showClearButton]="true"
                            [min]="0"
                            [max]="365">
                            <dx-validator>
                                <dxi-validation-rule
                                    type="required"
                                    [message]="'app.countryManagement.standardDeliveryDays.validation'| translate">
                                </dxi-validation-rule>
                            </dx-validator>
                        </dx-number-box>
                    </div>
                </div>
            </div>

            <div class="dx-fieldset">
                <dx-validation-summary
                    id="summary"
                    class="summary"
                ></dx-validation-summary>
                <dx-button
                    [hidden]="!countryForm.dirty && !countryForm.touched"
                    class="update_button"
                    [text]="'app.countryManagement.buttons.update' | translate"
                    type="success"
                    [useSubmitBehavior]="true">
                </dx-button>
            </div>
        </form>
        <br>
        <br>
        <div class="page__headline">
            <h2 [textContent]="'app.countryManagement.deliveryTimeCorrection' | translate">Delivery Time Correction</h2>
        </div>

        <dx-data-grid
            class="custom"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [remoteOperations]="true"
            [showColumnLines]="true"
            [showRowLines]="true"
            [wordWrapEnabled]="true"
            [dataSource]="this.plantDeliveryTimes">
            <dxo-editing
                [allowAdding]="true"
                [allowDeleting]="true"
                [allowUpdating]="true"
                [useIcons]="true"
                mode="popup">
                <dxo-form
                    [colCount]="1">
                    <dxi-item dataField="plantId"></dxi-item>
                    <dxi-item dataField="enabled"></dxi-item>
                    <dxi-item dataField="minDeliveryTime">
                        <dxi-validation-rule
                            type="required"
                            [message]="'app.countryManagement.regularExtraDays.validation'| translate">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxo-form>
            </dxo-editing>

            <dxi-column dataField="plantId"
                        [caption]="'app.countryManagement.plant'| translate"
                        [allowEditing]="true"
                        editCellTemplate="plantBoxEditor">
                <div *dxTemplate="let cellInfo of 'plantBoxEditor'">
                    <dx-select-box
                        [items]="warehouses"
                        [value]="getValue(cellInfo)"
                        [showDropDownButton]="true"
                        [searchEnabled]="true"
                        [showClearButton]="true"
                        (onValueChanged)="onValueChanged(cellInfo, $event)"
                        (onSelectionChanged)="cellInfo.component.updateDimensions()">
                    </dx-select-box>
                </div>
            </dxi-column>

            <dxi-column dataField="plantSapId"
                        [caption]="'app.countryManagement.sapId'| translate"
                        [allowEditing]="false">
            </dxi-column>

            <dxi-column dataField="minDeliveryTime"
                        [caption]="'app.countryManagement.minPossibleDeliveryTime'| translate"
                        [allowEditing]="true"
                        editCellTemplate="numberBoxEditor">
            </dxi-column>

            <div *dxTemplate="let cellInfo of 'numberBoxEditor'">
                <dx-number-box
                    [showClearButton]="true"
                    [showSpinButtons]="true"
                    [min]="1"
                    [max]="365"
                    [value]="getValue(cellInfo)"
                    (onValueChanged)="onValueChanged(cellInfo,$event)">
                </dx-number-box>
            </div>

            <dxi-column dataField="enabled"
                        dataType="boolean"
                        [caption]="'app.countryManagement.isActive'| translate"
                        [allowEditing]="true">
            </dxi-column>

            <dxi-column dataField="createdAt"
                        dataType="datetime"
                        [caption]="'app.countryManagement.createdAt'| translate"
                        [allowEditing]="true">
            </dxi-column>

            <dxi-column dataField="updatedAt"
                        dataType="datetime"
                        [caption]="'app.countryManagement.updatedAt'| translate"
                        [allowEditing]="true">
            </dxi-column>

            <dxi-column dataField="updatedBy"
                        [caption]="'app.countryManagement.updatedBy'| translate"
                        [allowEditing]="true">
            </dxi-column>
        </dx-data-grid>
        <br>
        <br>
        <div class="page__headline">
            <h2 [textContent]="'app.countryManagement.weekdayManagement' | translate">Weekday Management</h2>
        </div>
        <br>
        <dx-data-grid id="weekdayManagementGrid" #weekdayManagementGrid
            class="custom"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [remoteOperations]="true"
            [showColumnLines]="true"
            [showRowLines]="true"
            [wordWrapEnabled]="true"
            [dataSource]="this.weekdayManagement"
            (onSaving)="onSaving()">
            <dxo-editing
                [allowAdding]="false"
                [allowDeleting]="false"
                [allowUpdating]="true"
                [useIcons]="true"
                mode="form">
                <dxo-form
                    [colCount]="1">
                    <dxi-item dataField="weekday"></dxi-item>
                    <dxi-item dataField="cutOffTime">
                        <dxi-validation-rule
                            type="required"
                            [message]="'app.countryManagement.cutOffTime.validation'| translate">
                        </dxi-validation-rule>
                        <dxi-validation-rule
                            [type]="'pattern'"
                            [pattern]="'^([0-1][0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$'"
                            [message]="'Invalid time format. Please use HH:MM:SS.'">
                        </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="regularExtraDays">
                        <dxi-validation-rule
                            type="required"
                            [message]="'app.countryManagement.regularExtraDays.validation'| translate">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxo-form>
            </dxo-editing>

            <dxi-column dataField="weekday"
                        [caption]="'Weekdays'"
                        [allowEditing]="false">
            </dxi-column>

            <dxi-column dataField="cutOffTime"
                        [caption]="'app.countryManagement.cutOffTime.title'| translate"
                        [allowEditing]="true">
            </dxi-column>

            <dxi-column dataField="regularExtraDays"
                        [caption]="'app.countryManagement.regularExtraDays.title'| translate"
                        [allowEditing]="true"
                        editCellTemplate="numberBoxEditor">
            </dxi-column>

            <div *dxTemplate="let cellInfo of 'numberBoxEditor'">
                <dx-number-box
                    [showClearButton]="true"
                    [showSpinButtons]="true"
                    [min]="0"
                    [max]="365"
                    [value]="getValue(cellInfo)"
                    (onValueChanged)="onValueChanged(cellInfo,$event)">
                </dx-number-box>
            </div>
        </dx-data-grid>
        <br>
        <br>
        <div class="page__headline">
            <h2 [textContent]="'app.countryManagement.weekdayDisplay' | translate">Display Value</h2>
        </div>
        <br>
        <dx-data-grid id="weekdayDisplayValueGrid" #weekdayDisplayValueGrid
            class="custom"
            [showBorders]="true"
            [hoverStateEnabled]="true"
            [remoteOperations]="true"
            [showColumnLines]="true"
            [showRowLines]="true"
            [wordWrapEnabled]="true"
            [dataSource]="this.weekdayDisplayValues"
            (onRowUpdated)="orRowUpdated($event)">
            <dxo-editing
                [allowAdding]="false"
                [allowDeleting]="false"
                [allowUpdating]="true"
                [useIcons]="true"
                mode="form">
                <dxo-form
                    [colCount]="1">
                    <dxi-item dataField="weekday"></dxi-item>
                    <dxi-item *ngIf="country.id === 'BE'"
                              dataField="nl">
                        <dxi-validation-rule
                            [type]="'pattern'"
                            [pattern]="'^[a-zA-Z.,]+$'"
                            [message]="'Invalid input! Should not contain numbers or special characters.'">
                        </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item *ngIf="country.id === 'BE'"
                              dataField="fr">
                        <dxi-validation-rule
                            [type]="'pattern'"
                            [pattern]="'^[a-zA-Z.,]+$'"
                            [message]="'Invalid input! Should not contain numbers or special characters.'">
                        </dxi-validation-rule>
                    </dxi-item>
                    <dxi-item *ngIf="country.id !== 'BE'"
                              dataField="displayValue">
                        <dxi-validation-rule
                            [type]="'pattern'"
                            [pattern]="'^[a-zA-Z.,]+$'"
                            [message]="'Invalid input! Should not contain numbers or special characters.'">
                        </dxi-validation-rule>
                    </dxi-item>
                </dxo-form>
            </dxo-editing>
            <dxi-column dataField="weekday"
                        [caption]="'Weekdays'"
                        [allowEditing]="false">
            </dxi-column>
            <dxi-column *ngIf="country.id === 'BE'"
                        dataField="nl"
                        [caption]="'app.countryManagement.nl'| translate"
                        [allowEditing]="true">
            </dxi-column>
            <dxi-column *ngIf="country.id === 'BE'"
                        dataField="fr"
                        [caption]="'app.countryManagement.fr'| translate"
                        [allowEditing]="true">
            </dxi-column>
            <dxi-column *ngIf="country.id !== 'BE'"
                        dataField="displayValue"
                        [caption]="'app.countryManagement.displayValue'| translate"
                        [allowEditing]="true">
            </dxi-column>
        </dx-data-grid>
    </ng-template>
</div>
