<div class="page">

    <div class="page__headline">
        <span eciTranslate="app.deliveryProduct.title">Delivery Times for Products</span>
        <span eciTranslate="global.forCountry"
              translateValues="{country: '{{ country?.name }}'}">for {{ country?.name }}</span>
    </div>

    <br/><br/>

    <div class="page__grid mb-4">
        <eci-alert-error></eci-alert-error>
        <div>
            <dx-popup
                id="columnSelectionPopup"
                [(visible)]="isColumnsPopupVisible"
                [title]="'Select columns for export'">
                <dx-form>
                    <dxi-item>
                        <div class="dx-field">
                            <div class="dx-field-label">Selected columns</div>
                            <div class="dx-field-value">
                                <dx-tag-box
                                    [items]="options"
                                    (onValueChanged)="updateSelectedColumns($event)"
                                    [showClearButton]="true"
                                    [showSelectionControls]="true"
                                    [showDropDownButton]="true"
                                    placeholder="Select columns">
                                </dx-tag-box>
                            </div>
                        </div>
                    </dxi-item>
                    <dxi-item>
                        <dx-button id="submitButton" class="form-bottom-item" (onClick)="downloadCsv()" text=""
                                   type="success" stylingMode="outlined" useSubmitBehavior="true">Download
                        </dx-button>
                    </dxi-item>
                </dx-form>
            </dx-popup>
        </div>
        <div id="product-searches-container">
            <dx-popup
                id="saveSearchPopup"
                [(visible)]="isSaveSearchPopupVisible"
                [title]="this.translateService.get('app.deliveryProduct.search.saveTitle')">
                <dx-form id="searchForm" class="file-uploader-form">
                    <dxi-item>
                        <dxo-label [text]="this.translateService.get('app.deliveryProduct.search.name')"></dxo-label>
                        <dx-text-box [(value)]="searchName">
                        </dx-text-box>
                        <dxi-validation-rule
                            type="required"
                            message="Search name is required">
                        </dxi-validation-rule>
                    </dxi-item>

                    <dxi-item dataField="searchValue">
                        <dxo-label [text]="this.translateService.get('app.deliveryProduct.search.value')"></dxo-label>
                        <dx-text-box [(value)]="searchValue" disabled="true">
                        </dx-text-box>
                    </dxi-item>
                    <dxi-item>
                        <dx-button (onClick)="saveNewSearch()"
                                   [text]="this.translateService.get('app.deliveryProduct.search.save')"
                                   type="success" stylingMode="outlined" useSubmitBehavior="false"></dx-button>
                    </dxi-item>
                    <input type="hidden" name="gridState" [(ngModel)]="gridState">
                    <dx-button (onClick)="cancelSaveSearch()" text="Clear search" type="success" stylingMode="outlined"
                               useSubmitBehavior="false"></dx-button>
                </dx-form>
            </dx-popup>
            <dx-popup
                [title]="this.translateService.get('app.deliveryProduct.search.showTitle')"
                [(visible)]="isPopupVisible">
                <dx-data-grid id="searchContainer" #searchContainer
                              [dataSource]="searchesDataSource"
                              [remoteOperations]="true"
                              (onEditorPrepared)="onEditorPreparing($event)"
                              (onInitNewRow)="onNewEntry($event)"
                              (onRowClick)="setProductsGridState($event)"
                              (onRowInserted)="successNotification()"
                              (onRowUpdated)="successNotification()"
                              [allowColumnReordering]="true"
                              [allowColumnResizing]="true"
                              [columnAutoWidth]="true"
                              [showBorders]="true"
                              [showColumnLines]="true"
                              [showRowLines]="true"
                              [wordWrapEnabled]="true"
                              class="h-50" style="height: 100px;width: fit-content">
                    <dxo-editing
                        [allowAdding]="false"
                        [allowDeleting]="true"
                        [allowUpdating]="false"
                        [useIcons]="true"
                        mode="popup">
                        <dxo-popup
                            [height]="525"
                            [showTitle]="true"
                            [width]="700"
                            [title]="'app.deliveryProduct.editTitle' | translate">
                        </dxo-popup>
                    </dxo-editing>
                    <dxi-column
                        [caption]="'app.deliveryProduct.columns.searchName' | translate"
                        [minWidth]="150"
                        dataField="name"
                        dataType="string"
                        sortOrder="desc">
                    </dxi-column>
                    <dxi-column
                        [caption]="'app.deliveryProduct.columns.searchValue' | translate"
                        [minWidth]="250"
                        dataField="filterValue"
                        dataType="string">
                    </dxi-column>
                    <dxi-column
                        [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                        [caption]="'app.deliveryProduct.columns.createdAt' | translate"
                        [minWidth]="120"
                        dataField="createdAt"
                        dataType="datetime">
                    </dxi-column>
                    <dxi-column type="buttons" width="100">
                        <dxi-button name="edit"></dxi-button>
                        <dxi-button name="delete"></dxi-button>
                    </dxi-column>
                </dx-data-grid>
            </dx-popup>
        </div>
        <div id="searchPanel" class="search-panel">
            <div id="search-file-uploader">
                <div style="width: 400px">
                    <dx-file-uploader
                        #fileUploader
                        uploadUrl=""
                        [multiple]="false"
                        accept=".xlsx"
                        allowCanceling="true"
                        uploadMode="useForm"
                        id="fileUploaderId"
                        (onValueChanged)="uploadFile($event)"
                        [(value)]="fileName"
                        [width]="400"
                    ></dx-file-uploader>
                    <dx-button (onClick)="uploadFileToServer()" [hidden]="!fileToUpload"
                               [text]="this.translateService.get('app.deliveryProduct.fileUploader.upload')"
                               type="success" useSubmitBehavior="false"></dx-button>
                    <dx-button (onClick)="resetFileUploader()" [hidden]="!fileToUpload"
                               [text]="this.translateService.get('app.deliveryProduct.fileUploader.remove')"></dx-button>
                </div>
                <div id="buttons-container">
                    <div id="search-buttons-container">
                        <dx-button id="searchButton" class="search-show" (onClick)="showSearches()" text=""
                                   type="success" icon="search" stylingMode="outlined"
                                   useSubmitBehavior="false"></dx-button>
                        <dx-button id="saveButton" class="search-save" (onClick)="showNewSearchForm()" text=""
                                   type="success" icon="save" stylingMode="outlined"
                                   useSubmitBehavior="false"></dx-button>
                        <dx-button id="clearButton" class="search-clear" (onClick)="clearGridFilter()" text=""
                                   type="success" icon="clear" stylingMode="outlined"
                                   useSubmitBehavior="false"></dx-button>
                        <dx-button id="downloadButton" class="download-button" (onClick)="showColumnsSelection()"
                                   text="" type="success" icon="download" stylingMode="outlined"
                                   useSubmitBehavior="false"></dx-button>
                    </div>
                </div>
            </div>

        </div>
        <dx-data-grid id="gridContainer" #gridContainer
                      (onEditorPrepared)="onEditorPreparing($event)"
                      (onInitNewRow)="onNewEntry($event)"
                      (onRowInserted)="successNotification()"
                      (onRowUpdated)="successNotification()"
                      [allowColumnReordering]="true"
                      [allowColumnResizing]="true"
                      [columnAutoWidth]="true"
                      [dataSource]="dataSource"
                      [remoteOperations]="true"
                      [showBorders]="true"
                      [showColumnLines]="true"
                      [showRowLines]="true"
                      [wordWrapEnabled]="true"
                      [keyExpr]="'erpNumber'"
                      [(selectedRowKeys)]="selectedRows"
                      (onSelectionChanged)="onSelectionChanged($event)"
                      (onOptionChanged)="onOptionChanged($event)"
                      (onContentReady)="onContentReady($event)"
                      (onToolbarPreparing)="onToolbarPreparing($event)"
                      class="h-100">
            <dxo-paging [pageSize]="20"></dxo-paging>
            <dxo-editing
                [allowAdding]="false"
                [allowDeleting]="true"
                [allowUpdating]="true"
                [useIcons]="true"
                mode="popup">
                <dxo-popup
                    [height]="525"
                    [showTitle]="true"
                    [width]="700"
                    [title]="'app.deliveryProduct.editTitle' | translate">
                </dxo-popup>
                <dxo-form
                    [colCount]="1"
                    [elementAttr]="{ class: 'bank-holidays_edit' }">
                    <dxi-item
                        cssClass="delivery-products_edit_item"
                        dataField="erpNumber"
                        dataType="string"
                        [editorOptions]="{readOnly: true}">
                    </dxi-item>
                    <dxi-item
                        cssClass="delivery-products_edit_item"
                        dataField="name"
                        dataType="string"
                        [editorOptions]="{readOnly: true}">
                    </dxi-item>
                    <dxi-item
                        cssClass="delivery-products_edit_item"
                        dataField="daysForDelivery"
                        dataType="number">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                </dxo-form>
            </dxo-editing>
            <dxo-state-storing
                [enabled]="true"
                type="custom">
            </dxo-state-storing>
            <dxo-pager
                [allowedPageSizes]="[10, 20, 50, 100]"
                [showInfo]="true"
                [showPageSizeSelector]="true">
            </dxo-pager>
            <dxo-selection
                [showCheckBoxesMode]="'always'"
                [allowSelectAll]="false"
                [selectAllMode]="'page'"
                [deferred]="false"
                mode="multiple"
            >
            </dxo-selection>
            <dxo-load-panel [enabled]="true"></dxo-load-panel>
            <dxo-filter-row [visible]="true"></dxo-filter-row>
            <dxo-scrolling columnRenderingMode="virtual"></dxo-scrolling>
            <dxo-editing
                [allowAdding]="false"
                [allowDeleting]="false"
                [allowUpdating]="false"
                [useIcons]="true"
                mode="popup">
                <dxo-popup
                    [height]="525"
                    [showTitle]="true"
                    [width]="700"
                    [title]="'app.deliveryProduct.editTitle' | translate">
                </dxo-popup>
                <dxo-form
                    [colCount]="1"
                    [elementAttr]="{ class: 'bank-holidays_edit' }">
                    <dxi-item
                        cssClass="delivery-products_edit_item"
                        dataField="erpNumber"
                        dataType="string"
                        [editorOptions]="{readOnly: true}">
                    </dxi-item>
                    <dxi-item
                        cssClass="delivery-products_edit_item"
                        dataField="name"
                        dataType="string"
                        [editorOptions]="{readOnly: true}">
                    </dxi-item>
                    <dxi-item
                        cssClass="delivery-products_edit_item"
                        dataField="daysForDelivery"
                        dataType="number">
                        <dxi-validation-rule type="required"></dxi-validation-rule>
                    </dxi-item>
                </dxo-form>
            </dxo-editing>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.erpNumber' | translate"
                [minWidth]="150"
                dataField="erpNumber"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.name' | translate"
                [minWidth]="250"
                dataField="name"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.daysForDelivery' | translate"
                [minWidth]="150"
                dataField="daysForDelivery"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.deliveryTimeMaintained' | translate"
                [minWidth]="150"
                [trueText]="'Individual'"
                [falseText]="'Global'"
                dataField="deliveryTimeMaintained"
                dataType="boolean"
                cellTemplate="cellTemplate">
            </dxi-column>

            <div *dxTemplate="let data of 'cellTemplate'">
                <dx-button [style.background-color]=" data.value ? 'red' : 'green'"
                           [style.color]="'white'"
                           [style.border-color]="'grey'"
                           [style.width]="'75%'"
                           [style.height]="'40px'">
                    {{ data.value ? 'Individual' : 'Global' }}
                </dx-button>
            </div>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.inMasterArticle' | translate"
                [minWidth]="150"
                dataField="inMasterArticle"
                dataType="boolean">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.itemGroupCategory' | translate"
                [minWidth]="120"
                dataField="itemGroupCategory"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.deliveryStartDate' | translate"
                [minWidth]="150"
                dataField="deliveryStartDate"
                dataType="date">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.displayDeliveryDate' | translate"
                [minWidth]="120"
                dataField="displayDeliveryDate"
                dataType="boolean">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.isBulky' | translate"
                [minWidth]="120"
                dataField="isBulky"
                dataType="boolean">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.isHeader' | translate"
                [minWidth]="120"
                dataField="isHeader"
                dataType="boolean">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockLud' | translate"
                [minWidth]="150"
                dataField="realStockLud"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockPil' | translate"
                [minWidth]="150"
                dataField="realStockPil"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockHue' | translate"
                [minWidth]="150"
                dataField="realStockHue"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockBlo' | translate"
                [minWidth]="150"
                dataField="realStockBlo"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockRot' | translate"
                [minWidth]="150"
                dataField="realStockRot"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockSos' | translate"
                [minWidth]="150"
                dataField="realStockSos"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockRoo' | translate"
                [minWidth]="150"
                dataField="realStockRoo"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockVen' | translate"
                [minWidth]="150"
                dataField="realStockVen"
                dataType="number">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.stockEcht' | translate"
                [minWidth]="150"
                dataField="realStockP3Echt"
                dataType="number">
            </dxi-column>
            <dxi-column
                [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                [caption]="'app.deliveryProduct.columns.stockUpdatedAt' | translate"
                [minWidth]="150"
                dataField="stockUpdatedAt"
                dataType="datetime">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.articleStatus' | translate"
                [minWidth]="120"
                dataField="articleStatus"
                dataType="string">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.supplier' | translate"
                [minWidth]="120"
                dataField="supplier"
                dataType="string">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.supplierName' | translate"
                [minWidth]="120"
                dataField="supplierName"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.grossWeightValue' | translate"
                [minWidth]="120"
                dataField="grossWeightValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.grossWeightUnit' | translate"
                [minWidth]="120"
                dataField="grossWeightUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.heightValue'  | translate"
                [minWidth]="120"
                dataField="heightValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.heightUnit' | translate"
                [minWidth]="120"
                dataField="heightUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.widthValue' | translate"
                [minWidth]="120"
                dataField="widthValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.widthUnit' | translate"
                [minWidth]="120"
                dataField="widthUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.lengthValue'  | translate"
                [minWidth]="120"
                dataField="lengthValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.lengthUnit' | translate"
                [minWidth]="120"
                dataField="lengthUnit"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.girthValue' | translate"
                [minWidth]="120"
                dataField="girthValue"
                dataType="number">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.materialGroup' | translate"
                [minWidth]="120"
                dataField="materialGroup"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.productReferenceKey' | translate"
                [minWidth]="120"
                dataField="productReferenceKey"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.productReferenceOfKey' | translate"
                [minWidth]="120"
                dataField="productReferenceOfKey"
                dataType="string">
            </dxi-column>
            <dxi-column
                [caption]="'app.deliveryProduct.columns.productReferenceType' | translate"
                [minWidth]="120"
                dataField="productReferenceType"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.headProduct' | translate"
                [minWidth]="120"
                dataField="headProduct"
                dataType="string">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.variantProducts' | translate"
                [minWidth]="120"
                dataField="variantProducts"
                dataType="string">
            </dxi-column>

            <dxi-column
                [calculateFilterExpression]="dataGridUtilService.calculateFilterExpression"
                [caption]="'app.deliveryProduct.columns.updatedAt' | translate"
                [minWidth]="150"
                dataField="updatedAt"
                dataType="datetime"
                sortOrder="desc">
            </dxi-column>

            <dxi-column
                [caption]="'app.deliveryProduct.columns.comment' | translate"
                [minWidth]="250"
                dataField="comment"
                dataType="string">
            </dxi-column>


            <dxi-column type="buttons" width="100">
                <dxi-button name="edit"></dxi-button>
            </dxi-column>

            <div *dxTemplate="let data of 'totalGroupCount'">
                <div class="informer">
                    <h2 class="count">{{ this.getTotalRowCount() }}</h2>
                    <span
                        class="name">{{ this.translateService.get('app.deliveryProduct.toolbar.selectedArticles') }}</span>
                </div>
            </div>
        </dx-data-grid>
    </div>
</div>
